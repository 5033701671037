import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { HousekeeperDashboardRecordTask } from '../../models';
import { HousekeeperRecordTasksService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class HousekeeperRecordTasksStoreEffects {
  constructor(
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private dataService: HousekeeperRecordTasksService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ record, date }) =>
        this.dataService
          .load(record.property_id, {
            date,
            related_id: record.related.id,
            related_type: record.related.type,
          })
          .pipe(
            map(
              ({
                data,
              }: IResponseSuccess<HousekeeperDashboardRecordTask[]>) => {
                return fromActions.loadSuccess({ tasks: data, date, record });
              },
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.loadFailure(error));
            }),
          ),
      ),
    ),
  );

  setCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setCompletedRequest),
      switchMap(({ record, date, completed, taskId }) =>
        this.dataService
          .setCompleted(record.property_id, {
            date,
            completed,
            task_id: taskId,
            related_id: record.related.id,
            related_type: record.related.type,
          })
          .pipe(
            map(() => {
              this.notifications.success('notifications.generic_success');
              return fromActions.setCompletedSuccess({ taskId, completed });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.setCompletedFailure(error));
            }),
          ),
      ),
    ),
  );
}
