import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import { featureAdapter, initialState, State } from './state';

export const reducer = createReducer(
  initialState,

  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { tasks }) =>
    featureAdapter.setAll(tasks, {
      ...state,
      isLoading: false,
    }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.setCompletedSuccess, (state, { taskId, completed }) =>
    featureAdapter.updateOne({ id: taskId, changes: { completed } }, state),
  ),

  on(fromActions.resetState, () => initialState),
);

export function housekeeperRecordTasksReducer(
  state: State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
