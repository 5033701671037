import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { HousekeeperDashboardRecordTask } from '../../models';

export const featureAdapter =
  createEntityAdapter<HousekeeperDashboardRecordTask>({
    selectId: (model) => model.id,
  });

export interface State extends EntityState<HousekeeperDashboardRecordTask> {
  isLoading: boolean;
  error: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
});
