import { createAction, props } from '@ngrx/store';

import { HousekeeperDashboardTotals } from '../../models';

export const loadRequest = createAction(
  '[Housekeeper Statistics] Load Request',
  props<{
    property_id: number[];
    reference_date: string;
  }>(),
);
export const loadSuccess = createAction(
  '[Housekeeper Statistics] Load Success',
  props<{
    data: {
      today: HousekeeperDashboardTotals;
      tomorrow: HousekeeperDashboardTotals;
    };
  }>(),
);
export const loadFailure = createAction(
  '[Housekeeper Statistics] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Housekeeper Statistics] Reset State');
