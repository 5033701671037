import { createAction, props } from '@ngrx/store';

import {
  HousekeeperDashboardRecord,
  HousekeeperDashboardRecordTask,
} from '../../models';

export const loadRequest = createAction(
  '[Housekeeper Record Tasks] Load Request',
  props<{
    record: HousekeeperDashboardRecord;
    date: string;
  }>(),
);
export const loadSuccess = createAction(
  '[Housekeeper Record Tasks] Load Success',
  props<{
    record: HousekeeperDashboardRecord;
    date: string;
    tasks: HousekeeperDashboardRecordTask[];
  }>(),
);
export const loadFailure = createAction(
  '[Housekeeper Record Tasks] Load Failure',
  props<{ error: any }>(),
);

export const setCompletedRequest = createAction(
  '[Housekeeper Record Tasks] Set Completed Request',
  props<{
    record: HousekeeperDashboardRecord;
    date: string;
    completed: boolean;
    taskId: number;
  }>(),
);
export const setCompletedSuccess = createAction(
  '[Housekeeper Record Tasks] Set Completed Success',
  props<{
    taskId: number;
    completed: boolean;
  }>(),
);
export const setCompletedFailure = createAction(
  '[Housekeeper Record Tasks] Set Completed Failure',
  props<{ error: any }>(),
);

export const resetState = createAction(
  '[Housekeeper Record Tasks] Reset State',
);
