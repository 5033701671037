import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { HousekeeperDashboardTotals } from '../../models';
import { HousekeeperStatisticsService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class HousekeeperStatisticsStoreEffects {
  constructor(
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private statisticsService: HousekeeperStatisticsService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ type, ...payload }) =>
        this.statisticsService.load(payload).pipe(
          map(
            ({
              data: [data],
            }: IResponseSuccess<
              Array<{
                today: HousekeeperDashboardTotals;
                tomorrow: HousekeeperDashboardTotals;
              }>
            >) => {
              return fromActions.loadSuccess({
                data,
              });
            },
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );
}
