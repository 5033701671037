import { Action, createReducer, on } from '@ngrx/store';

import { StatsHeaderWidget } from '../../models';

import * as fromActions from './actions';
import { initialState, State } from './state';

export const reducer = createReducer(
  initialState,

  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { data: { today, tomorrow } }) => {
    const widgets: StatsHeaderWidget[] = [
      {
        title: { key: 'daily_closure_widgets_titles.arrival' },
        value: today.checkin.total,
        countLabel: today.checkin.total && {
          key: 'guests_icon_label',
          params: today.checkin,
          class: 'by-fs-15',
        },
        subtitle: {
          key: 'tomorrow_value',
          params: { value: tomorrow.checkin.total },
        },
      },
      {
        title: { key: 'daily_closure_widgets_titles.departure' },
        value: today.checkout.total,
        countLabel: today.checkout.total && {
          key: 'guests_icon_label',
          params: today.checkout,
          class: 'by-fs-15',
        },
        subtitle: {
          key: 'tomorrow_value',
          params: { value: tomorrow.checkout.total },
        },
      },
      {
        title: { key: 'daily_closure_widgets_titles.in_house' },
        value: today.in_home.total,
        countLabel: today.in_home.total && {
          key: 'guests_icon_label',
          params: today.in_home,
          class: 'by-fs-15',
        },
        subtitle: {
          key: 'tomorrow_value',
          params: { value: tomorrow.in_home.total },
        },
      },
    ];

    return {
      ...state,
      widgets,
      isLoading: false,
    };
  }),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.resetState, () => initialState),
);

export function housekeeperStatisticsReducer(
  state: State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
