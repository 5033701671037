import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { HousekeeperRecordTasksStoreEffects } from './effects';
import { housekeeperRecordTasksReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'housekeeperRecordTasks',
      housekeeperRecordTasksReducer,
    ),
    EffectsModule.forFeature([HousekeeperRecordTasksStoreEffects]),
  ],
})
export class HousekeeperRecordTasksStoreModule {}
