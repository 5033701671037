import { createFeatureSelector, createSelector } from '@ngrx/store';

import { featureAdapter, State } from './state';

export const selectState = createFeatureSelector<State>(
  'housekeeperRecordTasks',
);

export const selectTasks = featureAdapter.getSelectors(selectState).selectAll;

export const selectIsLoading = createSelector(
  selectState,
  (state) => state.isLoading,
);

export const selectError = createSelector(selectState, (state) => state.error);
